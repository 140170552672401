import React from 'react';
import { TypographyScale, Pill } from '@mdb/flora';

import AuthorImage from './author-image';
import { container, typographyContainer, stackedImageStyles } from './styles';
import { AuthorLockupProps } from './types';
import { getURLPath } from '../../utils/format-url-path';

const AuthorLockup: React.FunctionComponent<AuthorLockupProps> = ({
    className,
    authors,
    title,
    expandedNames = false,
    clickableLinks = false,
    size = 'small',
}) => {
    const nameStyles = clickableLinks
        ? {
              '&:hover': { textDecoration: 'underline' },
          }
        : {};

    const NameTag = clickableLinks ? 'a' : 'span';
    const authorNames = expandedNames ? (
        <>
            {authors.map(({ name, url, authorType }, index) => (
                <span key={name}>
                    <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                        <NameTag
                            className="author-name"
                            key={name}
                            sx={nameStyles}
                            {...(clickableLinks
                                ? {
                                      href: getURLPath(url),
                                      target: '_blank',
                                      rel: 'noreferrer',
                                  }
                                : {})}
                        >
                            {name}
                        </NameTag>
                        {size === 'large' &&
                            authorType &&
                            authorType !== 'MongoDB' && (
                                <Pill
                                    text="Guest Contributor"
                                    variant="badge"
                                    size="small"
                                    sx={{ mx: '4px' }}
                                />
                            )}
                    </span>
                    {authors.length > 1 && index < authors.length - 1
                        ? ', '
                        : ''}
                </span>
            ))}
        </>
    ) : (
        <>
            <NameTag
                className="author-name"
                sx={nameStyles}
                {...(clickableLinks
                    ? {
                          href: getURLPath(authors[0].url),
                          target: '_blank',
                          rel: 'noreferrer',
                      }
                    : {})}
            >
                {authors[0].name}
            </NameTag>
            {authors.length > 1 && ` (+${authors.length - 1})`}
        </>
    );
    return (
        <div
            aria-label="author-lockup-container"
            className={className}
            sx={container}
        >
            <div sx={{ display: 'flex', alignItems: 'center' }}>
                {authors.map((author, index) => {
                    let extraStyles = {};
                    if (index > 0) {
                        extraStyles = stackedImageStyles(size);
                    }
                    return (
                        <AuthorImage
                            key={author.name}
                            author={author}
                            sx={extraStyles}
                            size={size}
                        />
                    );
                })}
            </div>
            <div sx={typographyContainer}>
                {authors.length > 0 && (
                    <TypographyScale
                        variant={size === 'large' ? 'body1' : 'body3'}
                        customStyles={{
                            fontWeight: size === 'small' ? 400 : 500,
                        }}
                    >
                        <span>{authorNames}</span>
                    </TypographyScale>
                )}
                {!!title && (
                    <TypographyScale
                        variant={size === 'large' ? 'body3' : 'body4'}
                        color="secondary"
                    >
                        {title}
                    </TypographyScale>
                )}
            </div>
        </div>
    );
};

export default AuthorLockup;
