import theme from '@mdb/flora/theme';
import { ThemeUICSSObject } from 'theme-ui';
import { PillCategory } from '../../types/pill-category';
import { CardVariant } from './types';

export const pillStyles: ThemeUICSSObject = {
    marginBottom: 'inc30',
    // Weird values but that's what figma says for the flora pills.
    px: ['inc30', null, null, 'inc40'],
    py: ['inc10', null, null, 'inc20'],
    fontSize: ['9px', null, null, 'inc00'],
    fontWeight: '500',
    letterSpacing: ['2.5px', null, null, '3px'],
};

export const thumbnailStyles: ThemeUICSSObject = {
    borderRadius: 'inc30',
    objectFit: 'cover',
};

export const thumbnailWrapperStyles = (
    variant: CardVariant,
    category: PillCategory
): ThemeUICSSObject => {
    const mediumAspectRatio =
        category === 'Video' || ['small', 'medium', 'large'].includes(variant)
            ? { aspectRatio: '16/9' }
            : {};

    const order =
        variant === 'list' || (category === 'Podcast' && variant === 'medium')
            ? { order: -1 }
            : variant === 'large'
            ? { order: [null, null, -1] }
            : {};

    let mobileDisplay;
    let tabletDesktopDisplay;

    switch (variant) {
        case 'medium':
            mobileDisplay = tabletDesktopDisplay =
                category === 'News & Announcements' || category === 'Event'
                    ? 'none'
                    : 'block';
            break;
        case 'small':
            tabletDesktopDisplay = 'none';
            mobileDisplay = 'block';
            break;
        case 'related':
            mobileDisplay = tabletDesktopDisplay = 'none';
            break;
        default:
            mobileDisplay = tabletDesktopDisplay = 'block';
    }
    // Thumbnail dimensions vary.
    let mobileDimensions = null;
    let tabletDimensions = null;
    let desktopDimensions = null;
    let desktopWidthOverride = null;
    let mobileWidthOverride = null;

    switch (variant) {
        case 'large':
            mobileWidthOverride = '100%';
            if (category === 'Video') {
                desktopWidthOverride = '100%';
            } else {
                tabletDimensions = desktopDimensions = '180px';
            }
            break;
        case 'medium':
            if (category === 'Podcast') {
                mobileDimensions = tabletDimensions = '48px';
                desktopDimensions = '64px';
            } else {
                mobileWidthOverride = '100%';
            }
            break;
        case 'small':
            mobileWidthOverride = '100%'; // No tablet or desktop display, so no need to set those.
            break;
        case 'list':
            mobileDimensions = '96px';
            tabletDimensions = '180px';
            if (category === 'Video') {
                desktopWidthOverride = '317px';
            } else {
                desktopDimensions = '180px';
            }
            break;
    }

    return {
        display: [mobileDisplay, null, tabletDesktopDisplay],
        flexShrink: 0,
        position: 'relative',
        ...mediumAspectRatio,
        width: [
            mobileDimensions || mobileWidthOverride,
            null,
            tabletDimensions,
            desktopWidthOverride || desktopDimensions,
        ],
        height: [
            mobileDimensions || 'fit-content',
            null,
            tabletDimensions || 'fit-content',
            desktopDimensions || 'fit-content',
        ],
        ...order,
    };
};

export const cardWrapperStyles: ThemeUICSSObject = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: [
        theme.space.cards.paddingXXS,
        null,
        null,
        theme.space.cards.paddingXS,
    ],
    height: 'max-content',
    border: `1px solid ${theme.colors.card.default.border}`,
    boxShadow: 'level01',
    borderRadius: 'inc50',
    '&:hover': {
        border: `1px solid ${theme.colors.card.default.borderDarker}`,
        boxShadow: 'level03',
        cursor: 'pointer',
    },
    gap: ['inc40', null, null, 'inc50'],
    bg: theme.colors.panels.card.bg,
};

export const descriptionStyles = (
    variant: CardVariant,
    category: PillCategory
): ThemeUICSSObject => {
    let mobileDisplay;
    let tabletDesktopDisplay;
    switch (variant) {
        case 'large':
            tabletDesktopDisplay = '-webkit-box';
            mobileDisplay =
                category === 'Code Example' ? '-webkit-box' : 'none';
            break;
        case 'medium':
            tabletDesktopDisplay = mobileDisplay =
                category === 'Code Example' ? '-webkit-box' : 'none';
            break;
        case 'small':
            tabletDesktopDisplay = 'none';
            mobileDisplay =
                category === 'Code Example' ? '-webkit-box' : 'none';
            break;
        case 'list':
            tabletDesktopDisplay = '-webkit-box';
            mobileDisplay = 'none';
            break;
        case 'related':
            tabletDesktopDisplay = mobileDisplay = 'none';
            break;
    }
    return {
        display: [mobileDisplay, null, tabletDesktopDisplay],
        marginTop: [null, null, 'inc30'],
        WebkitLineClamp: 4,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    };
};

export const cardHeaderStyles = (
    variant: CardVariant,
    category: PillCategory
): ThemeUICSSObject => {
    const mobileGrid =
        variant === 'list' || (category === 'Podcast' && variant === 'medium')
            ? 'min-content auto'
            : 'auto';
    const tabletDesktopGrid =
        variant === 'small' ||
        variant === 'related' ||
        variant === 'live-video' ||
        (variant === 'medium' && category !== 'Podcast') ||
        (variant === 'large' && category === 'Video')
            ? 'auto'
            : 'min-content auto';
    return {
        gridTemplateColumns: [mobileGrid, null, tabletDesktopGrid],
        gap: ['inc30', null, 'inc50'],
        display: 'grid',
    };
};
