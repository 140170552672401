import React from 'react';
import Image from 'next/image';
import {
    TypographyScale,
    Pill,
    HorizontalRule,
    SystemIcon,
    ESystemIconNames,
} from '@mdb/flora';
import Truncate from 'react-truncate';
import ReactPlayer from 'react-player/lazy';
import styled from '@emotion/styled';

import AuthorLockup from '../author-lockup';

import {
    cardWrapperStyles,
    pillStyles,
    descriptionStyles,
    cardHeaderStyles,
    thumbnailWrapperStyles,
    thumbnailStyles,
} from './styles';
import {
    hasThumbnail,
    hasTags,
    hasDescription,
    hasAuthorLockup,
} from './utils';
import TagSection from '../tag-section';
import { CardProps, CardVariant } from './types';
import parse from 'html-react-parser';
import { parseAuthorsToAuthorLockup } from '../../utils/parse-authors-to-author-lockup';
import { getURLPath } from '../../utils/format-url-path';
import { h5Styles, h6Styles } from '../../styled/layout';
import EventIcon from '../icons/event-icon';
import { PillCategory } from '../../types/pill-category';
import isServerSide from '../../utils/is-server-side';
import { getPlaceHolderImage } from '../../utils/get-place-holder-thumbnail';

const CardThumbnail = ({
    thumbnail: { url = '', alt = '', city = '' } = {},
    contentType,
    variant,
    videoId = '',
    isComingSoon = false,
}: {
    thumbnail?: {
        url?: string;
        alt?: string;
        city?: string | null;
    };
    contentType: PillCategory;
    variant: CardVariant;
    videoId?: string;
    isComingSoon?: boolean;
}) => {
    const playButtonUrl = getURLPath('/play-button.svg', false) as string;

    const defaultThumbnail = (
        <Image
            alt={alt || 'MongoDB thumbnail image'}
            src={getPlaceHolderImage(url)}
            sx={thumbnailStyles}
            layout="fill"
        />
    );

    const StyledReactPlayer = styled(ReactPlayer)`
        position: absolute;
        top: 0;
        left: 0;
        > div {
            /* Redefines stacking context, allowing play button animation to stick on top */
            position: sticky;
        }
    `;

    const customThumbnails = {
        Podcast: (
            <Image
                alt="Play Button"
                src={playButtonUrl}
                sx={thumbnailStyles}
                layout="fill"
            />
        ),
        Event: url ? defaultThumbnail : <EventIcon text={city || ''} />,
        Video:
            variant === 'large' || variant === 'live-video' ? (
                <StyledReactPlayer
                    config={{
                        youtube: {
                            playerVars: {
                                rel: 0,
                            },
                        },
                    }}
                    playing={isComingSoon} // This will auto-display the "Notify me" panel, not acutally play anything.
                    controls
                    url={`https://www.youtube.com/watch?v=${videoId}`}
                    width="100%"
                    height="100%"
                    style={{
                        borderRadius: 8,
                        overflow: 'hidden',
                    }}
                />
            ) : (
                <>
                    {defaultThumbnail}
                    <div
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '100%',
                        }}
                    >
                        <Image
                            alt="Play Button"
                            src={playButtonUrl}
                            width={60}
                            height={60}
                        />
                    </div>
                </>
            ),
    } as { [category: string]: JSX.Element };

    return hasThumbnail(variant, contentType) ? (
        <div sx={thumbnailWrapperStyles(variant, contentType)}>
            {customThumbnails[contentType] || defaultThumbnail}
        </div>
    ) : null;
};

const externalLinkIcon = (
    <div
        sx={{
            fill: 'blue60',
            stroke: 'blue60',
            display: 'inline',
        }}
    >
        <SystemIcon name={ESystemIconNames.EXTERNAL} inheritColor />
    </div>
);

const Card: React.FunctionComponent<CardProps> = ({
    authors,
    displayDate,
    className,
    description,
    title,
    contentType,
    duration_in_min,
    pillCategory = contentType,
    tags,
    thumbnail,
    variant,
    slug,
    hideTagsOnMobile = true,
    secondaryTag = null,
    videoId,
    isComingSoon = false,
    type,
}) => {
    const truncatedDescription =
        description && parse(description ? description : '');

    const isExternalLink = isServerSide()
        ? false
        : new URL(document.baseURI).origin !==
          new URL(slug, document.baseURI).origin;

    const duration_time_text = () => {
        if (duration_in_min === undefined) return displayDate;

        const separator = ' | ';
        const isMedia = ['Podcast', 'Video'].includes(contentType);
        const durationText = isMedia
            ? `${duration_in_min} min`
            : `${duration_in_min} min read`;

        return [displayDate, durationText].filter(Boolean).join(separator);
    };
    return (
        <div
            sx={cardWrapperStyles}
            className={className}
            tabIndex={0}
            data-testid={`card-${variant}`}
        >
            {/* This absolute anchor is to avoid nesting anchor tags */}
            <a
                href={getURLPath(slug)}
                sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    left: 0,
                    top: 0,
                }}
                aria-label={title}
                {...(isExternalLink ? { target: '_blank' } : {})}
            />
            <div sx={cardHeaderStyles(variant, contentType)}>
                <div>
                    {variant !== 'live-video' && (
                        <Pill
                            sx={pillStyles}
                            variant="identifier"
                            text={pillCategory}
                            size="small"
                        />
                    )}
                    {secondaryTag}
                    <TypographyScale
                        variant="heading3"
                        sx={{
                            ...(variant === 'large' ? h5Styles : h6Styles),
                            ...(variant === 'large' && {
                                fontSize: ['inc30', 'inc30', 'inc50', 'inc80'],
                                lineHeight: [
                                    'inc20',
                                    'inc20',
                                    'inc30',
                                    'inc50',
                                ],
                            }),
                        }}
                    >
                        {title} {isExternalLink ? externalLinkIcon : ''}
                    </TypographyScale>
                    {hasDescription(variant, contentType) &&
                        variant !== 'live-video' &&
                        type !== 'Search' && (
                            <TypographyScale
                                variant="body2"
                                sx={descriptionStyles(variant, contentType)}
                            >
                                <Truncate lines={4} ellipsis={<span>...</span>}>
                                    {truncatedDescription}
                                </Truncate>
                            </TypographyScale>
                        )}
                    {type === 'Search' && (
                        <TypographyScale
                            variant="body2"
                            sx={descriptionStyles(variant, contentType)}
                        >
                            {truncatedDescription}
                        </TypographyScale>
                    )}
                </div>
                <CardThumbnail
                    thumbnail={thumbnail}
                    contentType={contentType}
                    variant={variant}
                    videoId={videoId}
                    isComingSoon={isComingSoon}
                />
                {variant === 'live-video' && (
                    <TypographyScale
                        variant="body2"
                        sx={descriptionStyles(variant, contentType)}
                    >
                        <Truncate lines={4} ellipsis={<span>...</span>}>
                            {truncatedDescription}
                        </Truncate>
                    </TypographyScale>
                )}
                {hasTags(variant) && tags && (
                    <TagSection
                        tags={tags}
                        disappearOnMobile={hideTagsOnMobile}
                        sx={{
                            gridColumnStart:
                                variant === 'list' ||
                                (contentType === 'Podcast' &&
                                    variant === 'medium') ||
                                (variant === 'large' && contentType !== 'Video')
                                    ? '2'
                                    : 'auto',
                        }}
                    />
                )}
            </div>

            <div>
                <HorizontalRule spacing="none" strokeWeight="medium" />

                <div
                    sx={{
                        marginTop: ['inc30', null, null, 'inc40'],
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <TypographyScale variant="body3">
                        {duration_time_text()}
                    </TypographyScale>
                    {authors &&
                        !!authors.length &&
                        hasAuthorLockup(variant, contentType) && (
                            <AuthorLockup
                                sx={{
                                    display: ['none', null, 'flex'],
                                    flexGrow: 0,
                                }}
                                authors={parseAuthorsToAuthorLockup(authors)}
                            />
                        )}
                </div>
            </div>
        </div>
    );
};

export default Card;
